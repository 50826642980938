import {createFeature, createReducer, on} from '@ngrx/store';
import {IMenuState} from '../types/menu-state.interface';
import {menuActions} from './actions';
import {EUserRole} from 'src/app/shared/enums/user-role.enum';
import {EAppRoutes} from 'src/app/shared/enums/app-routes.enum';

const initialState: IMenuState = {
  items: [
    {
      id: 0,
      name: 'PAGES.MENU.OVERVIEW',
      url: '/startseite',
      items: [],
      collapsed: true,
      icon: 'home',
      visibleRoles: [],
      disabled: false,
    },
    {
      id: 10,
      name: 'PAGES.MENU.ACCOUNT.ACCOUNT',
      url: '',
      items: [
        {
          id: 11,
          name: 'PAGES.MENU.ACCOUNT.PERSONAL_DATA',
          url: 'account',
          visibleRoles: [],
          disabled: false,
        },
        {
          id: 12,
          name: 'PAGES.MENU.ACCOUNT.PASSWORD_CHANGE',
          url: '/account/passwort',
          visibleRoles: [],
          disabled: false,
        },
      ],
      collapsed: true,
      icon: 'user',
      visibleRoles: [],
      disabled: false,
    },
    {
      id: 20,
      name: 'PAGES.MENU.ADMINISTRATION.ADMINISTRATION',
      url: '',
      items: [
        {
          id: 21,
          name: 'PAGES.MENU.ADMINISTRATION.MEMBERS',
          url: '/mitglieder',
          visibleRoles: [
            EUserRole.ADMINISTRATOR,
            EUserRole.BOARD,
            EUserRole.MANAGER,
            EUserRole.EMPLOYEE,
          ],
          disabled: false,
        },
      ],
      collapsed: true,
      icon: 'briefcase',
      visibleRoles: [
        EUserRole.ADMINISTRATOR,
        EUserRole.BOARD,
        EUserRole.MANAGER,
        EUserRole.EMPLOYEE,
      ],
      disabled: false,
    },
    {
      id: 40,
      name: 'PAGES.MENU.STAFF.TITLE',
      url: '',
      items: [
        {
          id: 41,
          name: 'PAGES.ABSENT_RECORD.WORKING_HOURS',
          url: EAppRoutes.STAFF_TIME_ACCOUNTS,
          visibleRoles: [EUserRole.ADMINISTRATOR, EUserRole.BOARD],
          disabled: false,
        },
        {
          id: 42,
          name: 'PAGES.MENU.STAFF.TEAM_CALENDAR',
          url: EAppRoutes.CALENDAR,
          visibleRoles: [EUserRole.ADMINISTRATOR, EUserRole.BOARD],
          disabled: false,
        },
        {
          id: 43,
          name: 'PAGES.STAFF.TIME_REGULATION',
          url: EAppRoutes.STAFF_TIME_REGULATION,
          visibleRoles: [EUserRole.ADMINISTRATOR, EUserRole.BOARD],
          disabled: false,
        },
      ],
      collapsed: true,
      icon: 'people-group',
      visibleRoles: [EUserRole.ADMINISTRATOR, EUserRole.BOARD],
      aliasView: false,
      disabled: false,
    },
    {
      id: 30,
      name: 'PAGES.ABSENT_RECORD.TIME_ACCOUNT',
      url: '',
      items: [
        {
          id: 31,
          name: 'PAGES.ABSENT_RECORD.WORKING_HOURS',
          url: EAppRoutes.TIME_ACCOUNT_MONTHLY,
          visibleRoles: [
            EUserRole.MANAGER,
            EUserRole.EMPLOYEE,
            EUserRole.TRAINEE,
          ],
          aliasView: true,
          disabled: false,
        },
        {
          id: 32,
          name: 'PAGES.ABSENT_RECORD.ABSENCES',
          url: EAppRoutes.ABSENCES,
          visibleRoles: [
            EUserRole.MANAGER,
            EUserRole.EMPLOYEE,
            EUserRole.TRAINEE,
          ],
          aliasView: true,
          disabled: false,
        },
        {
          id: 33,
          name: 'PAGES.MENU.STAFF.TEAM_CALENDAR',
          url: EAppRoutes.CALENDAR,
          visibleRoles: [
            EUserRole.MANAGER,
            EUserRole.EMPLOYEE,
            EUserRole.TRAINEE,
          ],
          disabled: false,
        },
        {
          id: 34,
          name: 'PAGES.STAFF.TIME_REGULATION',
          url: EAppRoutes.WORKTIMESCHEMES,
          visibleRoles: [
            EUserRole.MANAGER,
            EUserRole.EMPLOYEE,
            EUserRole.TRAINEE,
          ],
          disabled: false,
        },
      ],
      collapsed: true,
      icon: 'clock',
      visibleRoles: [EUserRole.MANAGER, EUserRole.EMPLOYEE, EUserRole.TRAINEE],
      aliasView: true,
      disabled: false,
    },
    {
      id: 80,
      name: 'PAGES.MENU.SETTINGS',
      url: EAppRoutes.SETTINGS,
      items: [],
      collapsed: true,
      icon: 'gear',
      visibleRoles: [],
      disabled: false,
    },
  ],
  selectedItem: 0,
};

const menuFeature = createFeature({
  name: 'menu',
  reducer: createReducer(
    initialState,
    on(menuActions.selectMenuItem, (state, action) => ({
      ...state,
      selectedItem: action.id,
    })),
    on(menuActions.toggleMenuItemGroup, (state, action) => ({
      ...state,
      items: state.items.map((item) =>
        item.id === action.id
          ? {...item, collapsed: action.collapsed}
          : {...item, collapsed: true},
      ),
    })),
    on(menuActions.aliasView, (state) => ({
      ...state,
      items: state.items.map((group) =>
        group.id === 10 || group.id === 20 || group.id === 40 || group.id === 80
          ? {...group, disabled: true, collapsed: true}
          : group,
      ),
    })),
    on(menuActions.reset, () => initialState),
  ),
});

export const {
  name: menuFeatureKey,
  reducer: menuReducer,
  selectMenuState,
  selectItems: selectMenuItems,
  selectSelectedItem: selectMenuCurrentActiveItem,
} = menuFeature;
